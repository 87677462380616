<template>
  <div>
    <div
      v-for="btn in buttons"
      :key="btn.id"
    >
      <q-btn
        v-if="btn.type === 'btn'"
        :disable="field.disabled || !!btn.disabled || schema.disabled"
        :key="btn.id"
        :color="btn.color || ''"
        :label="btn.label"
        :icon="btn.icon"
        :size="btn.size || 'md'"
        :class="btn.styleClasses || ''"
        no-caps
        @click="btn.onClick"
      />

      <q-btn-dropdown
        v-if="btn.type === 'dropdown'"
        color="primary"
        :label="btn.label"
        :disable="schema.disabled"
      >
        <q-list>
          <q-item
            v-for="option in btn.options"
            :key="option.id"
            clickable
            v-close-popup
            @click="btn.onClick(option, editor)"
          >
            <q-item-section>
              <q-item-label>
                {{ option.name }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>

      <div
        v-if="btn.type === 'chips'"
        class="row items-center"
      >
        <div
          v-if="btn.label"
          class="q-pa-sm text-subtitle1"
        >
          {{ btn.label }}
        </div>

        <div
          v-for="option in btn.options"
          :key="option.id"
          class="q-pa-xs"
        >
          <q-badge
            class="q-pa-sm clickable"
            :color="option.color || 'dark'"
            :label="option.name"
            :disable="schema.disabled"
            @click="btn.onClick(option, editor)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editor: {
      type: Object,
      default () {
        return null
      }
    },
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isFocused: false
    }
  },
  computed: {
    buttons () {
      return this.schema.toolbar.filter(x => typeof x.if !== 'boolean' || x.if)
    }
  }
}
</script>
